import request from '@/utils/request'


// 投诉反馈列表
export function feedbackList(params) {
  return request({
    url: '/MA_feedback/queryPage',
    method: 'get',
    params:params
  })
}

// 投诉反馈删除
export function feedbackDelete(params) {
  return request({
    url: '/MA_feedback/delete',
    method: 'get',
    params:params
  })
}

// 投诉反馈处理
export function feedbackDeal(params) {
  return request({
    url: '/MA_feedback/dealFeedback',
    method: 'get',
    params:params
  })
}


