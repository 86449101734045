<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="姓名">
          <el-input v-model="formInline.name" clearable placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      style="width: 100%; margin-top: 20px"
      stripe>
      <el-table-column prop="xuhao" label="序号" align="center" ></el-table-column>
      <el-table-column prop="areaname" label="所属社区" align="center" ></el-table-column>
      <el-table-column prop="name" label="姓名" align="center" ></el-table-column>
      <el-table-column prop="phone" label="联系方式" align="center" ></el-table-column>
      <el-table-column prop="description" label="反馈内容" align="center" ></el-table-column>
      <el-table-column label="处理状态" align="center" >
        <template slot-scope="scope">
          <span>{{ backCheckStatusLabel(scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="处理结果" prop="opinion" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 0">/</span>
          <span else>{{ scope.row.opinion }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="240">
        <template slot-scope="scope">
          <el-button style="margin-left: 20px" type="primary" v-if="scope.row.status === 0" @click="dealFeedback(scope.row)">去处理</el-button>
          <el-button type="warning" @click="deleteFeedback(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="fenyekuang">
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="currentPage"
        :page-sizes="fenye.pagesizes"
        :page-size="fenye.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="fenye.totalnum"
      >
      </el-pagination>
    </div>

    <!-- 审核积分 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dealDialog"
      title="反馈与建议处理"
      width="600px"
			:before-close="dealhandleClose"
    >
      <dealFeedback :dealFeedbackId="dealFeedbackId" @closeDealDialog="closeDealDialog" ref="auditpointRef"></dealFeedback>
    </el-dialog>
  </div>
</template>

<script>
import { feedbackList, feedbackDelete, feedbackDeal } from '@/api/feedback.js'
import dealFeedback from './dealFeedback'
import { sureMessageBox }　from '@/utils/common'
export default {
  data () {
    return {
      choiceDate0: '',
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = '';
          }
        },
        disabledDate:
        (time) => {
          let choiceDateTime = new Date(this.choiceDate0).getTime();
          const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1);
          const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
          const min = minTime;
          const newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
          const max = newDate < maxTime ? newDate : maxTime;
          //如果已经选中一个日期 则 返回 该日期前后一个月时间可选
          if (this.choiceDate0) {
            return time.getTime() < min || time.getTime() > max;
          }
          //若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate;
        }
      },
      formInline: {
        name: ''
      },
      tableData: [],
      currentPage: 1, //当前页
      fenye: {
        pagesizes: [10, 20, 50],
        pagesize: 10,
        totalnum: 0,
      }, //分页数据
      dealStatus: [{
        value: 0,
        label: '待处理' //已申请
      },{
        value: 2,
        label: '已解决'
      },{
        value: 1,
        label: '未解决'
      }],
      dealFeedbackId: 0,
      dealDialog: false,
    };
  },

  components: {
    dealFeedback
  },

  computed: {},

  mounted(){
    this.getList();
  },

  methods: {
    //返回状态文本
    backCheckStatusLabel(val){
      let item = this.dealStatus.filter(item => item.value === val)
      return item[0].label;
    },
    //处理反馈
    dealFeedback(row){
      this.dealDialog = true;
			this.dealFeedbackId = row.id;
    },
    //删除处理反馈
    deleteFeedback(row){
      sureMessageBox('是否确认删除条信息？删除后不可恢复，请谨慎操作').then(res=>{
        feedbackDelete({
          userid: this.GLOBAL.adminId(),
          id: row.id
        }).then(res=>{
          if(res.result == 200){
            this.$message.success('操作成功');
            this.currentPage = 1;
            this.getList();
          }else{
            this.$message.warning(res.description)
          }
        })
      }).catch(err=>{
        console.log(err)
      })
    },
    getList() {
      feedbackList({
        name: this.formInline.name,
        page: this.currentPage,
        pagesize: this.fenye.pagesize
      }).then(res => {
        if(res.result == 200) {
          res.detail.list.map((item, index) => {
            item.xuhao = index + 1;
          });
          this.tableData = res.detail.list;
          this.fenye.totalnum = res.detail.totalRow;
        }
      })
    },
    onSearch() {
      this.currentPage = 1;
      this.getList();
    },
    //展示条数改变时
    sizeChange(val) {
      this.fenye.pagesize = val;
      this.currentPage = 1;
      this.getList();
    },
    //当前页改变时
    currentChange(val) {
      this.currentPage = val;
      this.getList();
      console.log(val)
    },
		//取消审核积分操作
		closeDealDialog(data){
			// console.log(data)
			this.dealDialog = false;
			if(data == 'add'){
				this.onSearch();
			}
		},
    //关闭审核积分弹框
    dealhandleClose(done) {
      this.$refs.auditpointRef.resetForm();
			done()
    },
    examinePoint(row, num) {
      console.log("row:", row);
    },
  }
}

</script>
<style lang='scss' scoped>
.fenyekuang {
  width: 100%;
  height: 50px;
  margin-top: 1px;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>