<template>
  <div class="wrapper">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="left" label-width="130px" class="demo-ruleForm">
      <el-form-item label="是否解决" prop="dealstatus">
          <el-radio-group v-model="ruleForm.dealstatus" prop="dealstatus">
            <el-radio :label="2">已解决</el-radio>
            <el-radio :label="1">未解决</el-radio>
          </el-radio-group>
      </el-form-item>
      <el-form-item label="处理结果" prop="opinion">
        <el-input type="textarea" v-model="ruleForm.opinion"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button @click="cancelFn">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { examinePointOnce } from '@/api/examinePoint/examinePoint.js'
import {  feedbackDeal } from '@/api/feedback.js'
export default {
  name:"dealFeedback",
  components:{},
  props:{
    dealFeedbackId: {
      type: Number
    }
  },
  data(){
    return {
      ruleForm: {
        dealstatus: null,
        opinion: '',
      },
      rules: {
        dealstatus: [
          { required: true, message: '请选择处理状态', trigger: 'change' },
        ],
        opinion: [
          { required: true, message: '请输入处理结果', trigger: 'change' },
        ],
      }
    }
  },
  watch:{},
  created(){},
  mounted(){},
  computed:{},
  methods:{
    //取消添加
    cancelFn(type){
      this.$refs.ruleForm.resetFields();
      if(type){
        this.$emit('closeDealDialog',type)
      }else{
        this.$emit('closeDealDialog')
      }
    },
    //重置表单
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    //确认提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否确认提交?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            feedbackDeal({
              userid: this.GLOBAL.adminId(),
              id: this.dealFeedbackId,
              status: this.ruleForm.dealstatus,
              opinion: this.ruleForm.opinion
            }).then( res => {
              if(res.result == 200){
                this.$message.success('操作成功');
                this.cancelFn('add');
              }else{
                this.$message.error(res.description)
              }
            })
          }).catch(() => {

          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>

</style>